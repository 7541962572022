import React, { Suspense, lazy, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet'; // SEO ve meta tagler için
import ScrollToTop from './components/ScrollToTop'; // Sayfanın üstüne çıkma butonu
import ErrorBoundary from './components/ErrorBoundary'; // Hata yönetimi
import ProgressBar from './components/ProgressBar'; // Yükleme ilerleme çubuğu
import CookieConsent from 'react-cookie-consent'; // Cookie consent için ekleniyor
import axios from 'axios'; // Ziyaretçi bilgilerini kaydetmek için

// Sayfaları lazy load ediyoruz
const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const Downloads = lazy(() => import('./pages/Downloads'));
const Contact = lazy(() => import('./pages/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy')); // Privacy Policy sayfası
const Terms = lazy(() => import('./pages/Terms')); // Terms sayfası

// Sayfa geçiş animasyonları için ayarlamalar
const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.95,
  },
};

const pageTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
  duration: 0.6,
};

const PageTransitionWrapper = ({ children }) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="flex-grow"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  // Axios varsayılan ayarları
  axios.defaults.withCredentials = true; // Çerezleri her zaman dahil et
  axios.defaults.headers.common['Content-Security-Policy'] = "default-src 'self';"; // CSP başlıklarını ekleme
  // Ziyaretçi bilgilerini toplama işlemi
  useEffect(() => {
    axios.post('https://nidasoft.com.tr/track.php')
      .then(response => {
        console.log('Visitor data saved:', response.data);
      })
      .catch(error => {
        console.error('Error saving visitor data:', error);
      });
  }, []);

  return (
    <Router>
      <Helmet>
        <title>NidaSoft</title>
        <meta
          name="description"
          content="NidaSoft - Leading in software development, web design, desktop applications, and Excel automation."
        />
      </Helmet>

      {/* Sabit Header */}
      <Header />

      {/* Global Bildirimler */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: '350px', fontSize: '14px' }}
      />

      {/* Yükleme İlerleme Çubuğu */}
      <ProgressBar />

      {/* Hata yönetimi (Error Boundary) */}
      <ErrorBoundary>
        {/* Sayfa Yönlendirmeleri */}
        <Suspense
          fallback={
            <div className="flex justify-center items-center h-screen">
              <ClipLoader color="#27AE60" loading={true} size={50} />
              <p className="mt-4 text-gray-400">Loading... Please wait</p>
            </div>
          }
        >
          <PageTransitionWrapper>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy rotası */}
              <Route path="/terms" element={<Terms />} /> {/* Terms rotası */}
            </Routes>
          </PageTransitionWrapper>
        </Suspense>
      </ErrorBoundary>

      {/* Sayfa üstüne çıkma butonu */}
      <ScrollToTop />

      {/* Sabit Footer */}
      <Footer />

      {/* Cookie Consent */}
      <CookieConsent
        location="bottom"
        buttonText="Kabul Et"
        declineButtonText="Reddet"
        cookieName="nidasoftCookieConsent"
        secure={true}  // Yalnızca HTTPS üzerinden çerez gönderimi
        sameSite="Strict"
        style={{
          background: "#2B373B",
          fontSize: "16px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderTop: "3px solid #1abc9c",
        }}
        buttonStyle={{
          color: "#ffffff",
          background: "#1abc9c",
          fontSize: "15px",
          borderRadius: "8px",
          padding: "10px 20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background 0.3s ease",
        }}
        declineButtonStyle={{
          color: "#ffffff",
          background: "#888",
          fontSize: "15px",
          borderRadius: "8px",
          padding: "10px 20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background 0.3s ease",
        }}
        expires={150}
        onAccept={() => {
          toast.success("Çerez Kullanımını Kabul Ettiniz");
        }}
        onDecline={() => {
          toast.error("Çerez Kullanımı Reddedildi");
        }}
      >
        Web sitemiz çerezleri kullanmaktadır. Daha fazla bilgi için{" "}
        <a href="/privacy-policy" style={{ color: "#1abc9c", textDecoration: "underline" }}>
        Gizlilik Politikamızı
        </a>.
      </CookieConsent>
    </Router>
  );
}

export default App;
