import React from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png'; // Logo'yu içe aktardık
import { FaBars, FaTimes } from 'react-icons/fa'; // Mobil menü simgeleri için ikonlar

const Header = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <motion.header
      className="header flex justify-between items-center p-6 bg-gradient-to-r from-blue-900 to-purple-900 shadow-lg fixed top-0 left-0 w-full z-50"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1, ease: 'easeInOut' }}
    >
      {/* Logo ve Başlık */}
      <div className="flex items-center space-x-4">
        <img src={logo} alt="NidaSoft Logo" className="h-12 w-12 object-cover" />
        <motion.h1
          className="text-white font-extrabold text-3xl tracking-wide"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          NidaSoft
        </motion.h1>
      </div>

      {/* Navigation Links - Desktop */}
      <nav className="hidden md:flex space-x-8 text-lg">
        <motion.a
          href="/"
          className="relative text-gray-300 transition-all duration-300 hover:text-white group"
          whileHover={{ scale: 1.1 }}
        >
          Anasayfa
          {/* Hover alt çizgi */}
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </motion.a>

        <motion.a
          href="/about"
          className="relative text-gray-300 transition-all duration-300 hover:text-white group"
          whileHover={{ scale: 1.1 }}
        >
          Hakkımızda
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </motion.a>

        <motion.a
          href="/services"
          className="relative text-gray-300 transition-all duration-300 hover:text-white group"
          whileHover={{ scale: 1.1 }}
        >
          Hizmetler
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </motion.a>

        <motion.a
          href="/downloads"
          className="relative text-gray-300 transition-all duration-300 hover:text-white group"
          whileHover={{ scale: 1.1 }}
        >
          İndirmeler
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </motion.a>

        <motion.a
          href="/contact"
          className="relative text-gray-300 transition-all duration-300 hover:text-white group"
          whileHover={{ scale: 1.1 }}
        >
          İletişim
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </motion.a>
      </nav>

      {/* Mobil Menü İkonu */}
      <div className="md:hidden text-white text-3xl cursor-pointer" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>

      {/* Mobile Menu */}
      <div
        className={`absolute top-0 left-0 w-full h-screen bg-black bg-opacity-90 flex flex-col justify-center items-center space-y-6 transition-transform transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <a
          href="/"
          className="text-gray-300 hover:text-white text-2xl transition-all duration-300"
          onClick={toggleMenu}
        >
          Anasayfa
        </a>
        <a
          href="/about"
          className="text-gray-300 hover:text-white text-2xl transition-all duration-300"
          onClick={toggleMenu}
        >
          Hakkımızda
        </a>
        <a
          href="/services"
          className="text-gray-300 hover:text-white text-2xl transition-all duration-300"
          onClick={toggleMenu}
        >
          Hizmetler
        </a>
        <a
          href="/downloads"
          className="text-gray-300 hover:text-white text-2xl transition-all duration-300"
          onClick={toggleMenu}
        >
          İndirmeler
        </a>
        <a
          href="/contact"
          className="text-gray-300 hover:text-white text-2xl transition-all duration-300"
          onClick={toggleMenu}
        >
          İletişim
        </a>
      </div>
    </motion.header>
  );
};

export default Header;
