import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Sosyal medya ikonları

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-900 to-black text-gray-400 py-12 relative overflow-hidden">
      <div className="container mx-auto px-6 relative z-10">
        {/* Footer üst bölümü: 3 ana sütun (sol, orta, sağ) */}
        <div className="flex flex-col md:flex-row justify-between items-start text-left space-y-10 md:space-y-0">
          
          {/* Sol Bölüm: Şirket Hakkında Bilgi */}
          <div className="md:w-1/3">
            <h2 className="text-white text-2xl font-bold mb-4 animate-fade-in">NidaSoft Hakkında</h2>
            <p className="text-gray-400 text-md leading-relaxed">
              NidaSoft, yenilikçi web çözümleri, masaüstü uygulamaları ve otomasyon araçları konusunda çözüm 
              sunan bir kuruluştur. Amacımız, teknolojiyi kullanarak işletmeleri güçlendirmektir.
            </p>
          </div>

          {/* Orta Bölüm: Faydalı Linkler */}
          <div className="md:w-1/3 flex flex-col items-center">
            <h2 className="text-white text-2xl font-bold mb-4 animate-fade-in">Hızlı Bağlantılar</h2>
            <ul className="space-y-2">
              <li>
                <a href="/about" className="hover:text-white transition duration-300">Hakkımızda</a>
              </li>
              <li>
                <a href="/services" className="hover:text-white transition duration-300">Hizmetlerimiz</a>
              </li>
              <li>
                <a href="/contact" className="hover:text-white transition duration-300">İletişim</a>
              </li>
              <li>
                <a href="/privacy-policy" className="hover:text-white transition duration-300">Gizlilik Politikası</a>
              </li>
              <li>
                <a href="/terms" className="hover:text-white transition duration-300">Şartlar ve Koşullar</a>
              </li>
            </ul>
          </div>

          {/* Sağ Bölüm: Sosyal Medya Linkleri */}
          <div className="md:w-1/3 flex flex-col items-end">
            <h2 className="text-white text-2xl font-bold mb-4 animate-fade-in">Bizi Takip Edin</h2>
            <div className="flex space-x-4">
              <a href="https://facebook.com" className="text-gray-400 hover:text-white transition duration-300 hover:scale-110">
                <FaFacebookF className="text-2xl" />
              </a>
              <a href="https://twitter.com" className="text-gray-400 hover:text-white transition duration-300 hover:scale-110">
                <FaTwitter className="text-2xl" />
              </a>
              <a href="https://linkedin.com" className="text-gray-400 hover:text-white transition duration-300 hover:scale-110">
                <FaLinkedin className="text-2xl" />
              </a>
              <a href="https://instagram.com" className="text-gray-400 hover:text-white transition duration-300 hover:scale-110">
                <FaInstagram className="text-2xl" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer alt bölümü: Telif hakkı ve görsel efektler */}
        <div className="border-t border-gray-700 mt-8 pt-4 text-center">
          <p className="text-gray-500 text-sm">
            © 2024 NidaSoft - Tüm Hakları Saklıdır.
          </p>
        </div>
      </div>

      {/* Animasyonlu arka plan daireleri */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-r from-purple-600 to-blue-600 opacity-30 rounded-full blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-r from-pink-600 to-red-600 opacity-30 rounded-full blur-3xl animate-pulse"></div>
    </footer>
  );
};

export default Footer;
